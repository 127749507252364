@use "styles/sass/base/typography" as *;
@use "styles/sass/layouts/containers" as *;

.application-detail-page {
	@apply flex flex-col w-full gap-6 h-full p-6 pt-0;

	.application-detail-header {
		@apply flex items-center justify-between py-6;

		.application-back-title {
			@apply flex gap-1 items-center;

			.application-title {
				@apply font-heading-semibold-tiny h-fit;
			}
		}
	}

	.application-detail-content {
		@apply bg-neutral-high-pure-50 rounded-xl px-6 py-8 w-full self-center overflow-y-scroll;
		max-width: 800px;
	}

	.data-container {
		@apply flex flex-col w-full;
	}

	.description-field {
		@apply px-6 py-8 flex flex-col gap-3;

		.description-title {
			@include font-body-semibold-small;
		}

		.description-content {
			@include font-body-regular-small;
		}
	}

	.document-folder-section-container {
		@apply flex flex-col gap-4 my-4;

		.folder-item {
			@apply border border-neutral-high-200;

			.folder-header {
				@apply px-4 py-3;
			}
		}
	}

	.document-item {
		@apply flex px-4 py-3 border-b border-neutral-high-200;

		div.button-children {
			@apply flex-1;
			@include font-body-regular-small;
		}

		&:last-child {
			@apply border-b-0;
		}

		svg {
			display: none;
		}

		.document-name {
			@apply underline cursor-pointer break-words w-full;
		}
	}
}

@include media-max-md {
	.application-detail-page {
		@apply bg-neutral-high-pure-50 px-0;

		.application-detail-header {
			@apply pb-0;

			.application-back-title {
				@apply w-full;

				.application-title {
					@apply w-full p-6 pt-0 border-b-2 border-neutral-high-100;
				}
			}
		}

		.application-detail-content {
			@apply p-0 w-full flex flex-col justify-center overflow-y-visible;

			.accordion-section {
				@apply px-6;
			}
		}

		.button-footer {
			@apply flex flex-col gap-3 fixed bottom-0 left-0 w-full p-6 border-t-2 border-neutral-high-100 bg-neutral-high-pure-50;

			.button-component {
				@apply w-full;
			}
		}

		.data-container {
			@apply flex gap-4;

			.data-section-title {
				@include font-body-semibold-small;
				@apply pt-2;
			}
		}

		.description-field {
			@apply px-4 gap-2;

			.description-title {
				@include font-body-semibold-medium;
			}
		}

		.document-section-header {
			display: none;
		}

		.document-item {
			@apply flex-col gap-1 px-0 mx-4;

			.document-type {
				@include font-body-semibold-small;
			}

			svg {
				display: block;
			}
		}
	}
}
