@use "../abstracts/variables" as var;

html,
body {
	overflow-x: hidden;
}

.full-screen {
	height: 100vh;
	width: 100vw;
}

.height--full {
	height: 100%;
}

.width--full {
	width: 100%;
}

.width--fit-content {
	width: fit-content;
}

.container--full {
	height: 100%;
	width: 100%;
}

.wrapper-general {
	max-width: 85rem;
	width: 100%;
	margin: 0 auto;
	padding: 0 8.5rem;

	@media only screen and (max-width: 576px) {
			padding: 0 1.5rem;

		&.full-white {
			background-color: var.$neutral-high-pure-50;
			padding: 0 var.$spaces-8 !important;
		}
	}

	&.padding-default {
		padding-top: var.$spaces-4;
	}

	&.no-padding-top {
		padding-top: 0;
	}
}

%stack-shared {
	display: flex;
	flex-direction: column;
}

.stack {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			@extend %stack-shared;

			> *:not(:last-child) {
				margin-bottom: $space;
			}
		}
	}
}

%inline-shared {
	display: flex;
	flex-direction: row;
}

.inline {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			@extend %inline-shared;

			> *:not(:last-child) {
				margin-right: $space;
			}
		}
	}
}

.row > div {
	margin-bottom: var.$spaces-16;

	@media only screen and (max-width: 576px) {
		margin-bottom: var.$spaces-8;
	}
}

.desktop-show {
	display: block;
}

.desktop-hide {
	display: none;
}

.vw-links {
	@apply z-[1000] items-center gap-2;
}

vlibraswidget {
	display: flex;
}

@media only screen and (max-width: 770px) {
	.desktop-show {
		display: none;
	}

	.desktop-hide {
		display: block;
	}
}


// Media queries

@mixin media-min-sm {
	@media only screen and (min-width: map-get(var.$screen-size, 'sm')) {
		@content;
	}
}

@mixin media-min-md {
	@media only screen and (min-width: map-get(var.$screen-size, 'md')) {
		@content;
	}
}

@mixin media-min-lg {
	@media only screen and (min-width: map-get(var.$screen-size, 'lg')) {
		@content;
	}
}
@mixin media-min-xl {
	@media only screen and (min-width: map-get(var.$screen-size, 'xl')) {
		@content;
	}
}

@mixin media-max-sm {
	@media only screen and (max-width: map-get(var.$screen-size, 'sm')) {
		@content;
	}
}

@mixin media-max-md {
	@media only screen and (max-width: map-get(var.$screen-size, 'md')) {
		@content;
	}
}

@mixin media-max-lg {
	@media only screen and (max-width: map-get(var.$screen-size, 'lg')) {
		@content;
	}
}
@mixin media-max-xl {
	@media only screen and (max-width: map-get(var.$screen-size, 'xl')) {
		@content;
	}
}
