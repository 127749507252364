@use "styles/sass/layouts/containers" as *;

.cancel-application-modal-content {
	@apply rounded-lg;
	max-height: 85vh;
	max-width: 550px;
}

.cancel-application-modal-body {
	@apply px-0 py-6 gap-6;
	max-height: 85vh;
}

@include media-max-md {
	.cancel-application-modal-content {
		@apply w-screen h-screen max-h-screen max-w-full rounded-none;
	}

	.cancel-application-modal-body {
		@apply py-0;

		.modal-footer {
			@apply flex flex-col gap-3 fixed bottom-0 left-0 w-full p-6 border-t-2 border-neutral-high-100 bg-neutral-high-pure-50;
			flex-wrap: wrap-reverse !important;

			.button-component {
				@apply w-full;
			}
		}
	}
}
