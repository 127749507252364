.new-record-container {
    @apply rounded-lg bg-neutral-high-pure-50 flex-1 flex flex-col justify-between;

    .new-record-form {
        @apply p-8 pb-6;
    }

    .footer-buttons {
        @apply border-t border-neutral-high-100 flex gap-4 justify-end px-8 py-6;
    }
}
