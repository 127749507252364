/*** SPACING ***/
$spaces-0: 0; //0px
$spaces-4: 0.25rem; //4px
$spaces-8: 0.5rem; //8px
$spaces-10: 0.625rem; //10px
$spaces-12: 0.75rem; //12px
$spaces-16: 1rem; //16px
$spaces-24: 1.5rem; //24px
$spaces-28: 1.75rem; //28px
$spaces-32: 2rem; //32px
$spaces-36: 2.25rem; //36px
$spaces-40: 2.5rem; //40px
$spaces-48: 3rem; //48px
$spaces-56: 3.5rem; //56px
$spaces-64: 4rem; //64px
$spaces-80: 5rem; //80px

$spaces: (
	0: $spaces-0,
	1: $spaces-4,
	2: $spaces-8,
	3: $spaces-16,
	4: $spaces-24,
	5: $spaces-28,
	6: $spaces-32,
	7: $spaces-36,
	8: $spaces-40,
	9: $spaces-48,
	10: $spaces-56,
	11: $spaces-64,
	12: $spaces-80
);

/*** FONTS DS - https://www.figma.com/file/n4yyJx484fBNM7U5FI4jAK/%5BGLOBAL%5D-design-tokens-%5BV1.0.0%5D?type=design&node-id=0-1&mode=design&t=VshH6YsvUbXEU4V8-0 ***/

$fonts-10: 0.625rem; // 10px
$fonts-12: 0.75rem; // 12px
$fonts-14: 0.875rem; // 14px
$fonts-16: 1rem; // 16px
$fonts-18: 1.125rem; // 18px
$fonts-20: 1.25rem; // 20px
$fonts-24: 1.5rem; // 24px
$fonts-28: 1.75rem; // 28px
$fonts-32: 2rem; // 32px
$fonts-36: 2.25rem; // 36px
$fonts-40: 2.5rem; // 40px
$fonts-48: 3rem; // 48px
$fonts-64: 4rem; // 64px
$fonts-80: 5rem; // 80px
$fonts-96: 6rem; // 96px

$fonts-body: (
	tiny: $fonts-10,
	extra-small: $fonts-12,
	small: $fonts-14,
	medium: $fonts-16,
	large: $fonts-18
);

$fonts-heading: (
	tiny: $fonts-20,
	extra-small: $fonts-24,
	small: $fonts-28,
	medium: $fonts-32,
	large: $fonts-36,
	extra-large: $fonts-40
);

$fonts-display: (
	tiny: $fonts-48,
	small: $fonts-64,
	medium: $fonts-80,
	large: $fonts-96
);

$line-small: 125%;
$line-regular: 150%;

$line: (
	small: $line-small,
	regular: $line-regular
);

$weight-light: 300;
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;

$font_weights: (
	light: $weight-light,
	regular: $weight-regular,
	semibold: $weight-semibold,
	bold: $weight-bold
);

/*** COLORS DS - https://www.figma.com/file/n4yyJx484fBNM7U5FI4jAK/%5BGLOBAL%5D-design-tokens-%5BV1.0.0%5D?type=design&node-id=3-2&mode=design&t=1Lz6o6nj9Eme3ZAw-0 ***/

$neutral-low-300: #999999;
$neutral-low-400: #666666;
$neutral-low-pure-500: #1D1D1D;

$neutral-high-pure-50: #FFFFFF;
$neutral-high-100: #F0F0F0;
$neutral-high-200: #DBDBDB;

$feedback-positive-low-950: #1A2F2A;
$feedback-positive-pure-500: #00D4A1;
$feedback-positive-high-50: #E5FBF6;

$feedback-alert-low-950: #322E21;
$feedback-alert-pure-500: #F6C643;
$feedback-alert-high-50: #FFFAEA;

$feedback-negative-low-950: #331F21;
$feedback-negative-pure-500: #F83446;
$feedback-negative-high-50: #FCEEF2;

$feedback-focus-low-950: #272732;
$feedback-focus-pure-500: #8887F3;
$feedback-focus-high-50: #F3F3FE;

$colors: (
	neutral: (
		"low-300": $neutral-low-300,
		"low-400": $neutral-low-400,
		"low-pure-500": $neutral-low-pure-500,
		"high-pure-50": $neutral-high-pure-50,
		"high-100": $neutral-high-100,
		"high-200": $neutral-high-200
	),
	"feedback-positive": (
		"low-950": $feedback-positive-low-950,
		"pure-500": $feedback-positive-pure-500,
		"high-50": $feedback-positive-high-50
	),
	"feedback-alert": (
		"low-950": $feedback-alert-low-950,
		"pure-500": $feedback-alert-pure-500,
		"high-50": $feedback-alert-high-50
	),
	"feedback-negative": (
		"low-950": $feedback-negative-low-950,
		"pure-500": $feedback-negative-pure-500,
		"high-50": $feedback-negative-high-50
	),
	"feedback-focus": (
		"low-950": $feedback-focus-low-950,
		"pure-500": $feedback-focus-pure-500,
		"high-50": $feedback-focus-high-50
	)
);

/*** DIRECTIONS ***/
$directions: (
	l: "left",
	r: "right",
	t: "top",
	b: "bottom"
);

/*** LAYOUTS ***/
$header-height: 3.375rem;

/*** MEDIA QUERY ***/
// Based on https://tailwindcss.com/docs/responsive-design
$screen-size: (
	sm: 640px,
	md: 768px,
	lg: 1024px,
	xl: 1280px
);
