@use "../abstracts/variables" as var;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:-webkit-any-link {
	cursor: pointer;
	text-decoration: none;
}

input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.modal-open {
	width: 100%;
	overflow: hidden;
	position: fixed;
}

html, body, #root {
    background-color: var.$neutral-high-100;
    height: 100%;
}

#root {
	@apply w-full text-neutral-low-pure-500;
}
