@use "styles/sass/abstracts/variables" as var;

.table-pagination {
	@apply flex md:justify-between justify-end items-center px-4 py-3 w-full;

	.page-size-selector {
		@apply flex items-center gap-2;
		@apply text-sm font-light;

		color: #1D1D1D;

		select {
			@apply rounded px-2 h-8 w-16 border border-neutral-high-200;
		}
	}

	.page-num-selector {
		@apply flex text-sm font-light gap-2 text-neutral-low-pure-500;

		.page-selector {
			@apply flex items-center gap-2;

			.page-number-input {
				@apply rounded px-2 h-8 text-center border border-neutral-high-200;
				min-width: 3rem;

				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}
}
