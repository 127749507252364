@use "styles/sass/abstracts/variables" as var;

header.header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: var.$neutral-high-pure-50;
	z-index: 100;
	transition: top ease-in-out 0.7s;
	@apply h-24 py-4 flex justify-center border-b border-solid border-b-neutral-high-200;

	.wrapper-general {
		@apply flex justify-between max-w-[85rem] py-0 px-10;

		img.logo {
			height: 2rem;
			@apply mr-4;

			@media only screen and (max-width: 1000px) {
				@apply mr-8;
			}
		}

		nav {
			@apply flex items-center;

			ul {
				@apply gap-10 pr-10 mr-10 border-r border-neutral-high-200;

				@media only screen and (max-width: 1000px) {
					@apply gap-7 pr-5 mr-5;
				}

				a {
					@apply font-sora text-neutral-low-pure-500 hover:text-neutral-low-400 transition-all duration-300 ease-in-out;
					font-size: 0.938rem;
					line-height: 1.188rem;
				}
			}
		}


	}

	a,
	span {
		@apply text-neutral-low-pure-500 flex flex-row justify-between cursor-pointer;

		svg {
			@apply ml-1;
		}

		&:hover {
			@apply text-neutral-low-400;

			path {
				@apply fill-neutral-low-400;
			}
		}

		&:active {
			@apply text-neutral-low-400;

			path {
				@apply fill-neutral-low-400;
			}
		}
	}

    .button-link:hover  {
		@apply bg-transparent;

		.button-children {
			@apply underline text-neutral-low-pure-500;
		}
	}
}

.wrapper-profile {
	a.profile-name {
		@apply flex items-center gap-1;
	}

	button {
		@apply text-neutral-low-pure-500 flex flex-row justify-between;
	}

	.profile-options {
		@apply w-[13rem] absolute right-16 z-10 hidden flex-col pt-3;

		&:before {
			content: "";
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
			border-bottom: 10px solid #fff;
			@apply absolute right-6 top-1 border-t-0 border-solid w-0 h-0;
		}

		&::after {
			content: "";
			@apply h-[calc(100%-0.7rem)] w-full bg-neutral-high-pure-50 absolute -z-10 bottom-0 left-0 right-0 shadow-2xl;
		}

		a,
		button {
			@apply py-3 px-5 border-b border-solid border-neutral-high-200 text-sm;

			&.logout {
				@apply text-feedback-negative-pure-500;
			}
		}

		button {
			@apply border-none;
		}

		.divider {
			@apply border-t border-neutral-high-200 hidden;
		}
	}

	&:hover {
		.profile-options {
			@apply flex;
		}
	}
}

.login-button {
	width: 3rem;
	@apply flex flex-row justify-center ;

	div {
		@apply font-normal;
		letter-spacing: normal;
	}
}

.account-wrapper {
	position: relative;
	display: flex;

	button {
		font-size: 0.938rem;
		line-height: 1.188rem;
		@apply text-neutral-low-pure-500;

		&:hover {
			@apply text-neutral-low-400;

			path {
				@apply fill-neutral-low-400;
			}
		}

		&:active {
			@apply text-neutral-low-400;

			path {
				@apply fill-neutral-low-400;
			}
		}

		&.button-is-loading {
			@apply justify-center;
		}
	}

	.modal {
		button {
			@apply font-bold;
		}
	}
}

@media only screen and (max-width: 835px) {
	header.header {
		.wrapper-general {
			img.logo {
				@apply mr-3;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	header.header {
		@apply h-16;

		.account-wrapper {
			.wrapper-profile {
				@apply hidden;
			}
		}
	}

	.wrapper-profile {
		.profile-options {
			left: inherit;
			@apply w-[13rem] right-0;

			&::after {
				content: "";
				@apply shadow-none;
			}
		}

		&:hover {
			.profile-options {
				@apply hidden;
			}
		}
	}
}

@media only screen and (max-width: 576px) {
	header.header {
		.wrapper-general {
			@apply mb-0;
		}
	}
}
