@use '../abstracts/variables' as var;

h1,
.headline-1 {
    @apply font-sora text-[1.75rem] leading-[2.391rem] font-normal tracking-normal;
}

h2,
.headline-2 {
    @apply font-sora text-[1.625rem] leading-[2.22rem] font-normal tracking-normal;
}

h3,
.headline-3 {
    @apply font-sora text-[1.5rem] leading-[2.049rem] font-normal tracking-normal;
}

h4,
.headline-4 {
    @apply font-sora text-[1.25rem] leading-[1.708rem] font-normal tracking-normal;
}

h5,
.headline-5 {
    @apply font-sora text-[1.125rem] leading-[1.537rem] font-normal tracking-normal;
}

h6,
.headline-6 {
    @apply font-sora text-[1rem] leading-[1.366rem] font-normal tracking-normal;
}

.subtitle-1 {
    @apply font-sora text-[0.875rem] leading-[0.875rem] font-semibold tracking-normal;
}

.subtitle-2 {
    @apply font-sora text-[0.75rem] leading-[0.75rem] font-bold tracking-normal;
}

.body-1 {
    @apply font-sora text-[0.875rem] leading-[1.195rem] font-normal tracking-normal;
}

.body-2 {
    @apply font-sora text-[0.75rem] leading-[1.024rem] font-normal tracking-normal;
}

.button-large {
    @apply font-sora text-[0.875rem] leading-[0.875rem] font-bold tracking-wider;
}


@mixin font-display-light-large {
	font-size: var.$fonts-96;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-display-light-medium {
	font-size: var.$fonts-80;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-display-light-small {
	font-size: var.$fonts-64;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-display-light-tiny {
	font-size: var.$fonts-48;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-display-regular-large {
	font-size: var.$fonts-96;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-display-regular-medium {
	font-size: var.$fonts-80;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-display-regular-small {
	font-size: var.$fonts-64;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-display-regular-tiny {
	font-size: var.$fonts-48;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-display-semibold-large {
	font-size: var.$fonts-96;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-display-semibold-medium {
	font-size: var.$fonts-80;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-display-semibold-small {
	font-size: var.$fonts-64;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-display-semibold-tiny {
	font-size: var.$fonts-48;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-display-bold-large {
	font-size: var.$fonts-96;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-display-bold-medium {
	font-size: var.$fonts-80;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-display-bold-small {
	font-size: var.$fonts-64;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-display-bold-tiny {
	font-size: var.$fonts-48;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-light-extra-large {
	font-size: var.$fonts-40;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-light-large {
	font-size: var.$fonts-36;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-light-medium {
	font-size: var.$fonts-32;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-light-small {
	font-size: var.$fonts-28;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-light-extra-small {
	font-size: var.$fonts-24;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-light-tiny {
	font-size: var.$fonts-20;
	font-weight: var.$weight-light;
	line-height: var.$line-small;
}

@mixin font-heading-regular-extra-large {
	font-size: var.$fonts-40;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-regular-large {
	font-size: var.$fonts-36;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-regular-medium {
	font-size: var.$fonts-32;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-regular-small {
	font-size: var.$fonts-28;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-regular-extra-small {
	font-size: var.$fonts-24;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-regular-tiny {
	font-size: var.$fonts-20;
	font-weight: var.$weight-regular;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-extra-large {
	font-size: var.$fonts-40;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-large {
	font-size: var.$fonts-36;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-medium {
	font-size: var.$fonts-32;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-small {
	font-size: var.$fonts-28;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-extra-small {
	font-size: var.$fonts-24;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-semibold-tiny {
	font-size: var.$fonts-20;
	font-weight: var.$weight-semibold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-extra-large {
	font-size: var.$fonts-40;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-large {
	font-size: var.$fonts-36;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-medium {
	font-size: var.$fonts-32;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-small {
	font-size: var.$fonts-28;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-extra-small {
	font-size: var.$fonts-24;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-heading-bold-tiny {
	font-size: var.$fonts-20;
	font-weight: var.$weight-bold;
	line-height: var.$line-small;
}

@mixin font-body-light-large {
	font-size: var.$fonts-18;
	font-weight: var.$weight-light;
	line-height: var.$line-regular;
}

@mixin font-body-light-medium {
	font-size: var.$fonts-16;
	font-weight: var.$weight-light;
	line-height: var.$line-regular;
}

@mixin font-body-light-small {
	font-size: var.$fonts-14;
	font-weight: var.$weight-light;
	line-height: var.$line-regular;
}

@mixin font-body-light-extra-small {
	font-size: var.$fonts-12;
	font-weight: var.$weight-light;
	line-height: var.$line-regular;
}

@mixin font-body-light-tiny {
	font-size: var.$fonts-10;
	font-weight: var.$weight-light;
	line-height: var.$line-regular;
}

@mixin font-body-regular-large {
	font-size: var.$fonts-18;
	font-weight: var.$weight-regular;
	line-height: var.$line-regular;
}

@mixin font-body-regular-medium {
	font-size: var.$fonts-16;
	font-weight: var.$weight-regular;
	line-height: var.$line-regular;
}

@mixin font-body-regular-small {
	font-size: var.$fonts-14;
	font-weight: var.$weight-regular;
	line-height: var.$line-regular;
}

@mixin font-body-regular-extra-small {
	font-size: var.$fonts-12;
	font-weight: var.$weight-regular;
	line-height: var.$line-regular;
}

@mixin font-body-regular-tiny {
	font-size: var.$fonts-10;
	font-weight: var.$weight-regular;
	line-height: var.$line-regular;
}

@mixin font-body-semibold-large {
	font-size: var.$fonts-18;
	font-weight: var.$weight-semibold;
	line-height: var.$line-regular;
}

@mixin font-body-semibold-medium {
	font-size: var.$fonts-16;
	font-weight: var.$weight-semibold;
	line-height: var.$line-regular;
}

@mixin font-body-semibold-small {
	font-size: var.$fonts-14;
	font-weight: var.$weight-semibold;
	line-height: var.$line-regular;
}

@mixin font-body-semibold-extra-small {
	font-size: var.$fonts-12;
	font-weight: var.$weight-semibold;
	line-height: var.$line-regular;
}

@mixin font-body-semibold-tiny {
	font-size: var.$fonts-10;
	font-weight: var.$weight-semibold;
	line-height: var.$line-regular;
}

@mixin font-body-bold-large {
	font-size: var.$fonts-18;
	font-weight: var.$weight-bold;
	line-height: var.$line-regular;
}

@mixin font-body-bold-medium {
	font-size: var.$fonts-16;
	font-weight: var.$weight-bold;
	line-height: var.$line-regular;
}

@mixin font-body-bold-small {
	font-size: var.$fonts-14;
	font-weight: var.$weight-bold;
	line-height: var.$line-regular;
}

@mixin font-body-bold-extra-small {
	font-size: var.$fonts-12;
	font-weight: var.$weight-bold;
	line-height: var.$line-regular;
}

@mixin font-body-bold-tiny {
	font-size: var.$fonts-10;
	font-weight: var.$weight-bold;
	line-height: var.$line-regular;
}

.font-display-light-large {
	@include font-display-light-large;
}

.font-display-light-medium {
	@include font-display-light-medium;
}

.font-display-light-small {
	@include font-display-light-small;
}

.font-display-light-tiny {
	@include font-display-light-tiny;
}

.font-display-regular-large {
	@include font-display-regular-large;
}

.font-display-regular-medium {
	@include font-display-regular-medium;
}

.font-display-regular-small {
	@include font-display-regular-small;
}

.font-display-regular-tiny {
	@include font-display-regular-tiny;
}

.font-display-semibold-large {
	@include font-display-semibold-large;
}

.font-display-semibold-medium {
	@include font-display-semibold-medium;
}

.font-display-semibold-small {
	@include font-display-semibold-small;
}

.font-display-semibold-tiny {
	@include font-display-semibold-tiny;
}

.font-display-bold-large {
	@include font-display-bold-large;
}

.font-display-bold-medium {
	@include font-display-bold-medium;
}

.font-display-bold-small {
	@include font-display-bold-small;
}

.font-display-bold-tiny {
	@include font-display-bold-tiny;
}

.font-heading-light-extra-large {
	@include font-heading-light-extra-large;
}

.font-heading-light-large {
	@include font-heading-light-large;
}

.font-heading-light-medium {
	@include font-heading-light-medium;
}

.font-heading-light-small {
	@include font-heading-light-small;
}

.font-heading-light-extra-small {
	@include font-heading-light-extra-small;
}

.font-heading-light-tiny {
	@include font-heading-light-tiny;
}

.font-heading-regular-extra-large {
	@include font-heading-regular-extra-large;
}

.font-heading-regular-large {
	@include font-heading-regular-large;
}

.font-heading-regular-medium {
	@include font-heading-regular-medium;
}

.font-heading-regular-small {
	@include font-heading-regular-small;
}

.font-heading-regular-extra-small {
	@include font-heading-regular-extra-small;
}

.font-heading-regular-tiny {
	@include font-heading-regular-tiny;
}

.font-heading-semibold-extra-large {
	@include font-heading-semibold-extra-large;
}

.font-heading-semibold-large {
	@include font-heading-semibold-large;
}

.font-heading-semibold-medium {
	@include font-heading-semibold-medium;
}

.font-heading-semibold-small {
	@include font-heading-semibold-small;
}

.font-heading-semibold-extra-small {
	@include font-heading-semibold-extra-small;
}

.font-heading-semibold-tiny {
	@include font-heading-semibold-tiny;
}

.font-heading-bold-extra-large {
	@include font-heading-bold-extra-large;
}

.font-heading-bold-large {
	@include font-heading-bold-large;
}

.font-heading-bold-medium {
	@include font-heading-bold-medium;
}

.font-heading-bold-small {
	@include font-heading-bold-small;
}

.font-heading-bold-extra-small {
	@include font-heading-bold-extra-small;
}

.font-heading-bold-tiny {
	@include font-heading-bold-tiny;
}

.font-body-light-large {
	@include font-body-light-large;
}

.font-body-light-medium {
	@include font-body-light-medium;
}

.font-body-light-small {
	@include font-body-light-small;
}

.font-body-light-extra-small {
	@include font-body-light-extra-small;
}

.font-body-light-tiny {
	@include font-body-light-tiny;
}

.font-body-regular-large {
	@include font-body-regular-large;
}

.font-body-regular-medium {
	@include font-body-regular-medium;
}

.font-body-regular-small {
	@include font-body-regular-small;
}

.font-body-regular-extra-small {
	@include font-body-regular-extra-small;
}

.font-body-regular-tiny {
	@include font-body-regular-tiny;
}

.font-body-semibold-large {
	@include font-body-semibold-large;
}

.font-body-semibold-medium {
	@include font-body-semibold-medium;
}

.font-body-semibold-small {
	@include font-body-semibold-small;
}

.font-body-semibold-extra-small {
	@include font-body-semibold-extra-small;
}

.font-body-semibold-tiny {
	@include font-body-semibold-tiny;
}

.font-body-bold-large {
	@include font-body-bold-large;
}

.font-body-bold-medium {
	@include font-body-bold-medium;
}

.font-body-bold-small {
	@include font-body-bold-small;
}

.font-body-bold-extra-small {
	@include font-body-bold-extra-small;
}

.font-body-bold-tiny {
	@include font-body-bold-tiny;
}
