@use 'styles/sass/abstracts/variables' as var;

.Toastify__toast-container {
    @apply mt-24;
}

.Toastify__toast {
    @apply w-full text-sm font-normal p-4;
    border-radius: 0 !important;
    box-shadow: none !important;

    // DARK
    &.Toastify__toast-theme--dark {
        @apply bg-neutral-low-pure-500 text-neutral-high-pure-50;

        &.Toastify__toast--warning {
            @apply bg-feedback-alert-pure-500 text-neutral-low-pure-500;
        }

        &.Toastify__toast--error {
            @apply bg-feedback-negative-pure-500 text-neutral-low-pure-500;
        }

        &.Toastify__toast--success {
            @apply bg-feedback-positive-pure-500 text-neutral-low-pure-500;
        }
    }

    // LIGHT
    &.Toastify__toast-theme--light {
        @apply bg-neutral-high-pure-50 text-neutral-low-400 border border-solid border-neutral-high-200;
    }
}

.Toastify__progress-bar {
    visibility: hidden;
}

.Toastify__toast-body {
    padding: 0;
    margin: 0;
}

@media only screen and (max-width: 770px) {
    .Toastify__toast {
        max-width: calc(100% - 2rem);
        margin-left: 1rem !important;
    }

    .Toastify__toast-container {
        margin-top: 5rem !important;
    }
}
