@use "styles/sass/abstracts/variables" as var;

.container {
	position: relative;

	&.disabled {
		cursor: not-allowed;

		textarea {
			opacity: 0.65;
			cursor: not-allowed;
		}
	}

	.textarea-input-component {
		width: 100%;
		min-height: 112px;
		display: flex;
		align-items: center;
		position: relative;
		padding: var.$spaces-16;
		background-color: var.$neutral-high-pure-50;
		border: 1px solid transparent;
		border-radius: 5px;
		@apply text-neutral-low-400 border-neutral-high-200 bg-neutral-high-pure-50;

		&.error {
			@apply bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500;
		}

		&:focus {
			@apply border-neutral-low-300 shadow-sm;
		}

		button {
			width: var.$spaces-4;
			height: var.$spaces-4;
			border: none;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

::placeholder {
	color: var.$neutral-low-300;
}
