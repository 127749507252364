@use "styles/sass/base/typography" as *;
@use "styles/sass/layouts/containers" as *;

// Accordion Component
.accordion-component {
	.accordion-section {
		@apply pb-4;

		&:not(:last-child) {
			@apply mb-4;
		}

		.accordion-trigger.base-accordion-trigger {
			@apply bg-neutral-high-200 hover:bg-neutral-high-200 p-4;
			@include font-body-bold-small;
		}
	}

	.accordion-section-title {
		@apply pb-2 border-b-2 border-neutral-high-200 mb-6;

		@include font-body-semibold-medium;
	}

	.accordion-items {
		@apply flex flex-col gap-6;
	}

	.accordion-content {
		@apply p-0;
	}
}

// Base Accordion Component
.base-accordion-item {
	@apply border border-neutral-high-100 rounded cursor-pointer;

	&.base-accordion-item-expanded {
		.base-accordion-trigger {
			@apply rounded-t rounded-b-none py-2 px-3;

			> .base-accordion-icon {
				transform: rotate(180deg);
				transition: transform 0.2s linear;
			}
		}
	}

	&.base-accordion-item-collapsed {
		.base-accordion-trigger > .base-accordion-icon {
			transform: rotate(0deg);
			transition: transform 0.2s linear;
		}
	}

	.base-accordion-trigger {
		@apply flex items-center justify-between rounded;
	}
}

@include media-max-md {
	.accordion-component {
		.accordion-section {
			@apply border-b-2 border-neutral-high-200 pb-8;

			&:last-child {
				@apply border-b-0;
			}

			&:not(:last-child) {
				@apply mb-8;
			}
		}

		.accordion-section-title {
			@apply border-none;
		}

		.accordion-trigger {
			@apply bg-neutral-high-pure-50 hover:bg-neutral-high-pure-50 py-3;
			@include font-body-semibold-small;
		}

		.accordion-item {
			@apply border-neutral-high-100;
		}
	}
}

// ---- Overriding the Accordion lib styles ----
.amplify-accordion__item__trigger:focus {
	border: 0 !important;
	box-shadow: none !important;
}
