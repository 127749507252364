.folder-header {
	@apply bg-neutral-high-pure-50;
}

.folder-item {
	&[open], &.base-accordion-item-expanded {
		border-bottom: none !important;

		.folder-header {
			@apply bg-neutral-high-100 rounded-none;
		}

		.folder-content {
			@apply border rounded-b border-solid border-neutral-high-200 mb-2;
		}
	}
}

.document-item {
	@apply p-2 border-b border-neutral-high-200 overflow-hidden;

	&:last-child {
		@apply border-b-0;
	}
}
