.backoffice-folders-list {
	@apply flex flex-col text-sm p-4 gap-px;

	.expandable-line-item {
		@apply rounded-none border-0 border-b border-solid border-neutral-high-200 box-content;
	}
}

.select-folder-version {
	margin-left: -4px;

	[class*="select-folder-version-"] {
		@apply max-w-fit;

		.select__control {
			@apply border-0 bg-transparent min-h-fit cursor-pointer;

			&.select__control--is-focused {
				@apply border-0 shadow-none;
			}

			.select__value-container {
				@apply p-0;
			}

			.select__indicators {
				@apply h-fit
			}

			.select__dropdown-indicator {
				@apply p-0;
			}
		}

		.select__menu, .select__menu-list, .select__option {
			@apply w-20;
		}

		.select__option:hover, .select__option--is-focused {
			@apply bg-neutral-high-200;
		}

		.select__option--is-selected {
			@apply bg-neutral-low-pure-500;
		}
	}
}
