.document-template-modal {
	@apply pl-4;

	li {
		list-style-type: disc;
	}

	li li {
		list-style-type: circle;
		margin-left: 2rem;
	}
}

.expanded {
	@apply bg-neutral-high-100;

	button.header-button {
		@apply bg-neutral-high-100;

		&:hover {
			@apply bg-neutral-high-200;
		}
	}
}
