.drop-xs {
    box-shadow: 0px 1px 2px #F1F1F1;
}

.drop-s {
    box-shadow: 0px 2px 6px #F1F1F1;
}

.drop-m {
    box-shadow: 0px 6px 12px #F1F1F1;
}

.drop-l {
    box-shadow: 0px 17px 33px -2px #F1F1F1;
}

.drop-xl {
    box-shadow: 0px 25px 40px -10px #F1F1F1;
}

.drop-xxl {
    box-shadow: 0px 25px 60px -10px #F1F1F1;
}