@use "styles/sass/abstracts/variables" as var;

.container {
	position: relative;

	&.error {
		.input-component {
			background-color: var.$feedback-negative-high-50;
			border-color: var.$feedback-negative-pure-500;
			box-shadow: 0px 0px 1px 2.5px rgba(230, 0, 0, 0.05),
				0px 0px 0px 0.5px rgba(230, 0, 0, 0.5);
		}

		span.ico {
			bottom: 1.3rem;
		}
	}

	&.iptPassword {
		.input-component {
			padding-right: var.$spaces-48;
		}
	}

	&:has(:disabled) {
		cursor: not-allowed;

		input {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	span.ico {
		width: var.$spaces-4;
		height: var.$spaces-48;
		position: absolute;
		right: 1rem;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
}

.input-component {
	@apply border border-neutral-high-200 p-4 w-full rounded text-neutral-low-pure-500 h-12;

	&.short {
		padding: 0;
	}

	button {
		width: var.$spaces-4;
		height: var.$spaces-4;
		border: none;
		background-color: transparent;
		cursor: pointer;
	}
}

.input-mask {
	width: 100%;
	flex: 1;
	border: none;
	background-color: transparent;
	outline: none;
	font-size: var.$fonts-12;
	color: var.$neutral-low-400;
}

::placeholder {
	color: var.$neutral-low-300;
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active {
	box-shadow: 0 0 0 30px var.$neutral-high-pure-50 inset !important;
	-webkit-text-fill-color: var.$neutral-low-400 !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
