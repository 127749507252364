@use "styles/sass/base/typography" as *;

.section-container {
	@apply bg-neutral-high-pure-50 w-full rounded-lg border border-neutral-high-200;

	.section-name {
		@include font-body-semibold-medium;
		@apply border-b border-neutral-high-100 px-8 pt-6 pb-4;
	}

	.data-section-title {
		@include font-body-semibold-small;
		@apply px-0 py-4;
	}

	.name-value-display {
		@apply px-0;

		.name-display {
			@include font-body-regular-small;
			@apply text-neutral-low-400;
		}

		.value-display {
			@include font-body-regular-small;
		}
	}

	.description-field {
		@apply px-6 py-7 flex flex-col gap-3;

		.description-title {
			@include font-body-semibold-small;
		}

		.description-content {
			@include font-body-regular-small;
		}
	}
}
