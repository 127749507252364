.grid-cols-60 {
	grid-template-columns: repeat(60, minmax(0, 1fr)) !important;
}

.grid-cols-24 {
  grid-template-columns: repeat(24, minmax(0, 1fr)) !important;
}

thead {
	@apply bg-neutral-high-pure-50 shadow-[inset_0_-2px] shadow-neutral-low-pure-500;
	position: sticky;
	top: 0;
}
