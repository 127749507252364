@use "../abstracts/variables" as var;

.white-space--pre-line {
	white-space: pre-line;
}

.text-transform--capitalize {
	text-transform: capitalize;
}

.text-transform--uppercase {
	text-transform: uppercase;
}

.text-transform--lowercase {
	text-transform: lowercase;
}

.text-align--right {
	text-align: right;
}

.text-align--justify {
	text-align: justify;
}

.text-align--center {
	text-align: center;
}

.text-decoration--none {
	text-decoration: none;
}

.overflow-y--scroll {
	overflow-y: scroll;
}

.overflow-y--hidden {
	overflow-y: hidden;
}

.overflow-y--auto {
	overflow-y: auto;
}

.overflow--hidden {
	overflow: hidden;
}

.overflow-wrap--break-word {
	overflow-wrap: break-word;
}

.overflow-wrap--anywhere {
	overflow-wrap: anywhere;
}

.scrollbar-width--thin {
	scrollbar-width: thin;
}

.scrollbar-gutter--stable {
	scrollbar-gutter: stable;
}

.fixed-bottom-left {
	position: fixed;
	bottom: 0;
	left: 0;
}

.cursor--pointer {
	cursor: pointer;
}

.scroll-margin-top {
	scroll-margin-top: var.$header-height;
}

.margin-auto {
	margin: auto;
}

.margin {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			margin: #{$space};
		}
	}

	@each $letter, $dir in var.$directions {
		@each $suffix, $space in var.$spaces {
			&--#{$letter}-#{$suffix} {
				margin-#{$dir}: #{$space};
			}
		}
	}

	@each $suffix, $space in var.$spaces {
		&--x-#{$suffix} {
			margin-left: #{$space};
			margin-right: #{$space};
		}
	}

	@each $suffix, $space in var.$spaces {
		&--y-#{$suffix} {
			margin-top: #{$space};
			margin-bottom: #{$space};
		}
	}
}

.padding {
	@each $suffix, $space in var.$spaces {
		&--#{$suffix} {
			padding: #{$space};
		}
	}

	@each $letter, $dir in var.$directions {
		@each $suffix, $space in var.$spaces {
			&--#{$letter}-#{$suffix} {
				padding-#{$dir}: #{$space};
			}
		}
	}

	@each $suffix, $space in var.$spaces {
		&--x-#{$suffix} {
			padding-left: #{$space};
			padding-right: #{$space};
		}
	}

	@each $suffix, $space in var.$spaces {
		&--y-#{$suffix} {
			padding-top: #{$space};
			padding-bottom: #{$space};
		}
	}
}

.padding-bottom-sticky-footer {
	padding-bottom: calc(54px + var.$spaces-4);
}

.break-word {
	word-break: break-word;
}

$border: 1px solid var.$neutral-high-100;

.border-bottom {
	border-bottom: $border;
}

.border-top {
	border-top: $border;
}
