@use "styles/sass/abstracts/variables" as var;
@use "styles/sass/base/typography" as *;

.external-applications-table {
	@apply rounded-lg bg-neutral-high-pure-50 flex-1;

	table {
		@apply w-full;
	}

	thead {
		@apply bg-neutral-high-pure-50 shadow-[inset_0_-2px] shadow-neutral-low-pure-500;
		position: sticky;
		top: 0;

		tr th {
			@apply pt-6 pb-4 pr-2 text-left;
			@include font-body-bold-small;

			&.actions-column {
				@apply text-center;
			}
		}
	}

	tbody tr {
		@apply border-b-2 border-neutral-high-100;

		td {
			@include font-body-regular-small;
			height: 68px;
			padding-right: 10px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			&.process-number-column {
				max-width: 10vw;
			}

			&.highway-name-column {
				max-width: 18vw;
			}

			&.intervention-type-column {
				max-width: 14vw;
			}

			&.actions-column {
				@apply flex justify-center items-center;
			}
		}
	}

	thead tr th,
	tbody tr td {
		&:first-child {
			@apply pl-4;
		}

		&:last-child {
			@apply pr-4;
		}
	}
}
