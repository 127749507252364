@use "styles/sass/base/typography" as *;

.application-list {
	@apply py-4 px-2 flex-1 bg-neutral-high-pure-50 rounded-lg flex flex-col gap-4 overflow-auto;

	.application-card {
		@apply border border-neutral-high-200 rounded-lg p-4;

		.status-label {
			@include font-body-semibold-tiny;
		}

		.divider {
			@apply border-b-2 border-neutral-high-100 my-4;
		}
	}
}
