.container-step {
    @apply  w-full;

    img {
        @apply w-full;
    }

    .content {
        @apply mt-8;
    }
}

@media screen and (max-width: 768px) {
    .container-step {
        max-width: 100%;
        @apply w-full mx-auto px-0;

        .content {
            @apply mt-8 sm:mt-4;
        }
    }
}