@use 'styles/sass/abstracts/variables' as var;

.label {
	@apply text-sm;

  &.margin {
    @apply mb-1;
  }

  span.optional {
    color: var.$neutral-low-300;
  }
}
