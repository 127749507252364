$transition: transform 0.5s, visibility 0.5s;

.selection-footer {
    @apply bg-neutral-high-pure-50 text-neutral-low-pure-500 flex items-center justify-between px-8 py-4;

    position: fixed;
    width: 100vw;
    visibility: hidden;
    bottom: 0;
    left: 0;
    z-index: 10;
    transform: translateY(100vh);
    transition: $transition;
    border-top: 2px solid #C6C7C8;
    font-size: 14px;

    &.selection-footer-open {
        visibility: visible;
        transform: translateY(0);
        transition: $transition;
    }

    .children {
        @apply flex gap-4;
    }

    b {
        font-size: 16px;
        padding-right: 1rem;
    }
}