.container-how-it-works {
    @apply border-t-0;
    .list-steps {
        @apply flex gap-8 sm:gap-6 mt-6 justify-between;
    }
}

@media only screen and (max-width: 768px) {
    .container-how-it-works {
        .wrapper-general {
            @apply flex flex-col;
        }

        .list-steps {
            @apply flex-col gap-8;
        }
    }
}