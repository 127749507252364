@use "styles/sass/base/typography" as *;
@use "styles/sass/layouts/containers" as *;

.data-section-title {
    @include font-body-semibold-extra-small;
    @apply px-4 py-3;
}

.name-value-display {
    @apply flex px-4 py-3 border-b border-neutral-high-100;

    &:last-child {
        @apply border-b-0;
    }

    .name-display {
        @include font-body-regular-extra-small;
        @apply flex-1;
    }

    .value-display {
        @include font-body-regular-extra-small;
        @apply flex-1;
    }
}

@include media-max-md {
    .name-value-display {
        @apply flex-col gap-1 px-0 mx-4;

        .name-display {
            @include font-body-semibold-small;
            @apply text-neutral-low-400;
        }

        .value-display {
            @include font-body-regular-small;
        }
    }
}
