.container-modal-gateway {
	@apply w-screen h-screen fixed top-0 left-0;
	z-index: 1000;

	&__content {
		max-width: 31.25rem;
		@apply w-full bg-neutral-high-pure-50 shadow-lg fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50;
	}

	&__body {
		@apply flex flex-col px-10 py-16 gap-8;

		> img {
			@apply w-fit;
		}
	}

	&__overlay {
		content: "";
		@apply w-screen h-screen top-0 left-0 bg-neutral-low-pure-500 bg-opacity-50;
	}
}

@media only screen and (max-width: 768px) {
	.container-modal-gateway {
		&__content {
			max-width: calc(100% - 3rem);
		}

		&__body {
			@apply p-10;
		}

		&__footer {
			@apply flex-col items-center gap-7;
		}
	}
}
