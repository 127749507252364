.container-header-info {
    .container-summary {
        @apply max-w-full px-6 py-20 border-b border-solid border-neutral-high-200;
    }

    .icon {
        svg {
            width: 160px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .container-header-info {
        .icon {
            svg {
                width: 120px;
            }
        }
    }
}
