@use 'styles/sass/abstracts/variables' as var;

.container-loading {
	@apply w-full flex justify-center;

	.ico-loading {
        animation: rotation 2s infinite linear;
	}
}


@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
