.button-segment {
	@apply md:border-x-0 md:border-l md:rounded-none border-neutral-low-pure-500 m-1 md:m-0;

	&:first-child {
		@apply md:rounded-l md:border-l border-neutral-low-pure-500;
	}

	&:last-child {
		@apply md:rounded-r md:border-r border-neutral-low-pure-500;
	}
}
