@use "styles/sass/abstracts/variables" as var;

.container-checkbox {
	width: 100%;
	position: relative;

	&.error input[type="checkbox"]{
		@apply bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500;
	}
  label {
    border-color: transparent;
  }

  &:focus-within {
    label {
      @apply border-neutral-low-pure-500;
    }
  }

	input[type="checkbox"] {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		display: flex;
		align-items: center;
	}

}
