@use "styles/sass/abstracts/variables" as var;

.container-summary {
    width: 100%;
    max-width: 32rem;
    position: relative;
    border-radius: 4px;
    padding: var.$spaces-8;
    background-color: var.$neutral-high-pure-50;

    @media only screen and (max-width: 770px) {
        padding: var.$spaces-16;
    }
}
