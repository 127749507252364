@use "styles/sass/abstracts/variables" as var;

div.container {
	&:not(.short) {
		div.select-button,
		div.multiselect-button {
			padding: 0.325rem 0.1rem;
		}
	}

	&.error .select-button,
	&.error .multiselect-button {
		@apply bg-feedback-negative-high-50 border-feedback-negative-pure-500 focus-visible:outline-feedback-negative-pure-500;
	}

	.select-button {
		height: 3rem;
	}

	.select-button,
	.multiselect-button {
		@apply flex items-center justify-between gap-2;
		@apply focus-visible:outline-none;
		background-color: var(--high-pure-50);
		border: 1px solid #DBDBDB;
		border-radius: 4px;

		&:hover {
			border-color: var(---gray-400);
		}
	}

	&.select--is-disabled {
		cursor: not-allowed;

		.select-button-disabled,
		.multiselect-button-disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.multiselect-tag {
		@apply flex items-center justify-around gap-1;
		@apply py-1 px-2;
		color: inherit;
		max-width: fit-content;
		background-color: rgb(230, 230, 230);

		span.multiselect-option-label {
			@apply truncate text-sm;
		}

		button.multiselect-remove {
			@apply cursor-pointer;
			font-size: 16px;
			border: none;
			background: none;
			padding: 0;
		}

		button.multiselect-remove:hover {
			color: var.$feedback-alert-low-950;
		}
	}

	.multiselect-input,
	.select-input {
		@apply focus:outline-none;
		flex: 1;
		border: none;

		&:hover {
			cursor: pointer;
		}
	}

	.clear-button {
		color: #DBDBDB;
		font-size: 24px;
		margin-right: 0.5rem;
	}

	.select-options,
	.multiselect-options {
		@apply absolute z-10 w-full rounded-md;
		@apply max-h-56 overflow-auto shadow-lg;
		font-size: inherit;
		background-color: #FFFFFF;
		border: 0.5px solid #DBDBDB;

		.select-option,
		.multiselect-option {
			@apply px-4 py-2 cursor-pointer;
			color: var(--neutral-low-pure-500);

			&:hover {
				color: var(--neutral-low-400);
				background-color: rgb(230, 230, 230);
			}

			&.active-option {
				color: var(--neutral-low-pure-500);
			}
		}

		.no-options {
			@apply px-4 py-2 italic;
			color: var(--neutral-low-pure-500);
		}
	}

	.multiselect-remove-all {
		font-size: 20px;
	}

	&.error .combobox-button,
	&.error .multiselect-button {
		@apply border-feedback-negative-pure-500 bg-feedback-negative-high-50;
	}
}
