@use "styles/sass/base/typography" as *;

.status-label {
	@apply px-3 py-1 w-fit h-fit rounded;
	@include font-body-semibold-small;

	&.status-analysis,
	&.status-new-message {
		@apply bg-feedback-alert-high-50;
		color: #7b4f05;
	}
	&.status-concluded,
	&.status-cancelled {
		@apply bg-neutral-high-100 text-neutral-low-pure-500;
	}
}
